import { all } from "redux-saga/effects";
import { getCategoryWatcher } from "./category";
import { getContactWatcher } from "./contact";
import { getProductWatcher } from "./product";
import { getProductDetailsWatcher } from "./productDetails";
import { getSizeWatcher } from "./size";
import { getTestimonialWatcher } from "./testimonial";
import { getTextureWatcher } from "./texture";

export default function* rootSaga() {
  yield all([
    getCategoryWatcher(),
    getProductWatcher(),
    getSizeWatcher(),
    getTextureWatcher(),
    getProductDetailsWatcher(),
    getTestimonialWatcher(),
    getContactWatcher(),
  ]);
}
