import React from "react";
import cn from "classnames";
import styles from "./footer.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  aboutPattern,
  contactPattern,
  getAboutRoute,
  getCategoryRoute,
  getContactRoute,
  getHomeRoute,
  getPolicyRoute,
  homePattern,
  policyPattern,
} from "../../routes";
import { ArrowRight } from "react-bootstrap-icons";
import logo from "../../../src/assets/images/mysurface.in_logo.png";
function Footer() {
  const { pathname } = useLocation();
  return (
    <div className={cn(styles["footer_bottom"])}>
      <Container>
        <Row className={"mt-4 mb-5 justify-content-between align-items-center"}>
          <Col md={8} lg={5}>
            <div className={cn(styles["link_area"])}>
              <img src={logo} alt="logo" style={{width:"50%"}}/>
              <p className="mt-4 pt-3">
              Karya Graphito, with 8+ years of experience in
              digital and branding solutions, is the creator of
              My Surface, an innovative product that enhances
              customer engagement
              </p>
              <p className="mt-3">
                <a href="mailto:info@mysurface.in" style={{color:"white"}}>info@mysurface.in</a> | M: +91 9712918171<br/>
                Ahmedabad, Gujarat, INDIA
              </p>
            </div>
          </Col>
          {/* <Col md={4} lg={3}>
            <div className={cn(styles["link_home"])}>
              <Link
                to={getHomeRoute()}
                className={
                  "justify-content-center justify-content-sm-end justify-content-lg-start"
                }
                style={
                  pathname === homePattern ? { color: "var(--primary)" } : null
                }
              >
                Home
              </Link>
              <Link
                to={getAboutRoute()}
                className={
                  "justify-content-center justify-content-sm-end justify-content-lg-start"
                }
                style={
                  pathname === aboutPattern ? { color: "var(--primary)" } : null
                }
              >
                About
              </Link>
              <Link
                to={getContactRoute()}
                className={
                  "justify-content-center justify-content-sm-end justify-content-lg-start"
                }
                style={
                  pathname === contactPattern
                    ? { color: "var(--primary)" }
                    : null
                }
              >
                Enquiry
              </Link>
              <Link
                to={getPolicyRoute()}
                className={
                  "justify-content-center justify-content-sm-end justify-content-lg-start"
                }
                style={
                  pathname === policyPattern
                    ? { color: "var(--primary)" }
                    : null
                }
              >
                Privacy & Policies
              </Link>
            </div>
          </Col> */}
          <Col lg={4}>
            <div className={cn(styles["link_home"], "mb-0")}>
              <span className={cn(styles["productinfo"])}>Product of : <strong>Karya Graphito</strong></span>
            </div>
            <div className={cn(styles["link_home"])}>
                <span style={{color:"white"}}>&#169; {new Date().getFullYear()} mysurface.in <br/>All rights reserved</span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={cn(styles["copyright_area"])}>
        <p>
          <span>
            Developed by -{" "}
            <a target="_blank" href="https://arnatechnosoft.com/">Arna Technosoft</a>
          </span>          
        </p>
      </div>
    </div>
  );
}

export default Footer;
